import { Envelope, Instagram, Facebook } from "react-bootstrap-icons";
import { imageProps } from "./imageProps";
import { paths } from "./paths";

export const Navigation_ARRAY = () => {
  return [
    {
      link: paths?.index,
      title: "Home",
      withLeftNavbar: true,
      withRightNavbar: false,
      withFooter: true,
      icon: false,
      footerClassName: "footer_link mb-2",
    },
    {
      link: paths?.about,
      title: "Over ÑEZ",
      withLeftNavbar: true,
      withRightNavbar: false,
      withFooter: true,
      icon: false,
      footerClassName: "footer_link mb-2",
    },
    {
      link: paths?.collections,
      title: "Collecties",
      withLeftNavbar: true,
      withRightNavbar: false,
      withFooter: true,
      icon: false,
      footerClassName: "footer_link mb-2",
    },

    {
      link: paths?.contact,
      title: "Contact",
      withLeftNavbar: false,
      withRightNavbar: true,
      withFooter: true,
      icon: false,
      footerClassName: "footer_link mb-2",
    },
    {
      link: paths?.faq,
      title: "FAQ",
      withLeftNavbar: false,
      withRightNavbar: true,
      withFooter: true,
      icon: false,
      footerClassName: "footer_link mb-2",
    },
  ];
};
export const Navigation_PDF_ARRAY = () => {
  return [
    {
      link: paths?.legalNotice,
      title: "Wettelijke kennisgeving",
      withFooter: true,
      footerClassName: "footer_link mb-2",
    },
    {
      link: paths?.generalConditions,
      title: "Algemene voorwaarden",
      withFooter: true,
      footerClassName: "footer_link mb-2",
    },
    {
      link: paths?.coockiePolicy,
      title: "Cookiebeleid",
      withFooter: true,
      footerClassName: "footer_link mb-2",
    },
  ];
};
export const Navigation_CONTACT_ARRAY = () => {
  const iconSize = 18;
  const iconClassName = "social_icon";
  return [
    {
      link: "mailto:info@nezceramics.be",
      title: "info@nezceramics.be",
      icon: <Envelope size={iconSize} className={iconClassName} />,
      footerClassName: "footer_link mb-2",
    },
  ];
};

export const Socialmedia_ARRAY = () => {
  const iconSize = 24;
  return [
    {
      target: "_blank",
      ariaLabel: "Facebook",
      link: "https://www.facebook.com/inez.declerck",
      icon: (
        <Facebook width={iconSize} height={iconSize} className="facebook" />
      ),
    },
    {
      target: "_blank",
      ariaLabel: "Instagram",
      link: "https://www.instagram.com/inezdeclerckceramics",
      icon: (
        <Instagram width={iconSize} height={iconSize} className="instagram" />
      ),
    },
  ];
};

export const FAQ_items_ARRAY = () => {
  return [
    {
      header: "Voedselveilig",
      body: "Alle Keramiek is voedselveilig! De glazuren en soorten klei worden zorgvuldig geselecteerd.",
    },
    {
      header: "Vaatwasser",
      body: "Alle keramiek kan in de vaatwasser. De keramiek bakken we minimum op 1200 graden voor het gebruiksklaar is. Dus ja hoor, alle keramische serviezen kunnen in de vaatwasser!",
    },
    {
      header: "Microgolf en oven",
      body: "Microgolf en oven, dat kan! Let er echter op dat je keramiek niet wordt blootgesteld aan plotselinge temperatuurveranderingen. Verwarm je keramiek mee in de oven om het verschil in temperatuur te verminderen. Plaats het na het opwarmen ook nooit direct op een koud oppervlak. Door de temperatuurschok kan je object barsten.",
    },
    {
      header: "Producten op maat",
      body: (
        <>
          Producten op maat laten maken is mogelijk. Bestellingen van op maat
          gemaakte keramiek moeten minstens 1 maand op voorhand geplaatst
          worden.
          <p>Enkele voorbeelden van producten op maat:</p>
          <ul>
            <li>Gepersonaliseerde keramiek (met naam)</li>
            <li>Urne</li>
            <li>Volledig servies sets</li>
          </ul>
        </>
      ),
    },
  ];
};

export const Carsousel_HOME_ARRAY = () => {
  return [
    {
      src: imageProps?.carousel1?.src,
      alt: imageProps?.carousel1?.alt,
      height: imageProps?.carousel1?.height,
      width: imageProps?.carousel1?.width,
    },
    {
      src: imageProps?.carousel2?.src,
      alt: imageProps?.carousel2?.alt,
      height: imageProps?.carousel2?.height,
      width: imageProps?.carousel2?.width,
    },
    {
      src: imageProps?.carousel3?.src,
      alt: imageProps?.carousel3?.alt,
      height: imageProps?.carousel3?.height,
      width: imageProps?.carousel3?.width,
    },
  ];
};

export const Carsousel_ABOUT_ARRAY = () => {
  return [
    {
      src: imageProps?.carousel_aboutus1?.src,
      alt: imageProps?.carousel_aboutus1?.alt,
      height: imageProps?.carousel_aboutus1?.height,
      width: imageProps?.carousel_aboutus1?.width,
    },
    {
      src: imageProps?.carousel_aboutus2?.src,
      alt: imageProps?.carousel_aboutus2?.alt,
      height: imageProps?.carousel_aboutus2?.height,
      width: imageProps?.carousel_aboutus2?.width,
    },
    {
      src: imageProps?.carousel_aboutus3?.src,
      alt: imageProps?.carousel_aboutus3?.alt,
      height: imageProps?.carousel_aboutus3?.height,
      width: imageProps?.carousel_aboutus3?.width,
    },
  ];
};

export const Features_ARRAY = () => {
  return [
    {
      title: "Unieke keramiek",
      isFirstItem: true,
      text: (
        <>
          Bij Ñezceramics kan u handgemaakte Keramiek vinden in diverse stijlen.
          Alle keramiek is met liefde en toewijding gemaakt en dit in kleine
          oplagen.
        </>
      ),
      src: imageProps?.featuresGeneral?.src,
      alt: imageProps?.featuresGeneral?.alt,
      height: imageProps?.featuresGeneral?.height,
      width: imageProps?.featuresGeneral?.width,
      src2: imageProps?.featuresGeneral_extra?.src,
      alt2: imageProps?.featuresGeneral_extra?.alt,
      height2: imageProps?.featuresGeneral_extra?.height,
      width2: imageProps?.featuresGeneral_extra?.width,
    },
    {
      title: "Doordachte ontwerpen",
      text: (
        <>
          De creaties zijn doordrongen van een diepgeworteld verlangen, het
          verlangen om verwondering op te roepen. Om mensen uit te nodigen om
          stil te staan bij de schoonheid van het ambacht en de mogelijkheden
          van klei.
          <br />
          <br />
          Ontdek handgemaakte keramiek waarin elk ontwerp doordacht is. Waar de
          vormgeving centraal staat.
        </>
      ),
      src: imageProps?.features1?.src,
      alt: imageProps?.features1?.alt,
      height: imageProps?.features1?.height,
      width: imageProps?.features1?.width,
    },
    {
      title: "Uitnodigende serviezen",
      text: (
        <>
          Ontdek unieke serviezen, die de perfecte setting creëren voor{" "}
          <b>gezellige en sfeervolle diners.</b>
        </>
      ),

      src: imageProps?.features2?.src,
      alt: imageProps?.features2?.alt,
      height: imageProps?.features2?.height,
      width: imageProps?.features2?.width,
    },
    {
      title: "Tijd voor Keramiek",
      text: (
        <>
          Niet alle keramiek staat op de website wegens de liefde voor de
          draaitafel. Neem een kijkje op instagram om nieuwe keramiek te
          ontdekken en een blik achter de schermen te werpen.
          <br />
          <br />
          Heb je iets gezien op instagram? U mag steeds contact opnemen via{" "}
          <a className="link_btn" href="mailto:info@nezceramics.be">
            info@nezceramics.be
          </a>{" "}
          of via Instagram chat!
        </>
      ),

      src: imageProps?.features3?.src,
      alt: imageProps?.features3?.alt,
      height: imageProps?.features3?.height,
      width: imageProps?.features3?.width,
    },
  ];
};

export const AboutUs_ARRAY = () => {
  return [
    {
      isFirstItem: true,
      title: "Welkom bij Ñezceramics",
      text: (
        <>
          Te midden van draaiende potten, de kneedbaarheid van klei en het
          vertrouwde geluid van mijn gereedschap ontstaan hier unieke stukken
          die met veel goesting zijn gemaakt. Ik nodig u graag uit om Ñez
          Ceramics te ontdekken, een plek waar mijn passie voor esthetiek,
          natuur, design, fotografie en de kunst van handgemaakte keramiek
          samenkomen.
          <br />
          <br />
          In mijn atelier, omringd door de rustgevende schoonheid van Mont de
          l'Enclus, ontstaan unieke keramiekstukken, elk gemaakt met mijn liefde
          voor het ambacht.
          <br />
          <br />
          Mijn reis begon met een diepe fascinatie voor grafische vormgeving,
          design en fotografie gevoed door mijn opleiding en naaste omgeving. Ik
          ontdekte mijn echte passie via het schetsen van vazen en servies, en
          het betoverende spel van lijnen op doek. In de dans van kleuren en
          contouren voelde ik de vreugde van expressie. Echter, het werd
          bijzonder toen ik voor het eerst de wereld van keramiek betrad.
        </>
      ),
      src: imageProps?.aboutus1?.src,
      src2: imageProps?.aboutus1_second?.src,
      alt: imageProps?.aboutus1?.alt,
      alt2: imageProps?.aboutus1_second?.alt,
      height: imageProps?.aboutus1?.height,
      height2: imageProps?.aboutus1_second?.height,
      width: imageProps?.aboutus1?.width,
      width2: imageProps?.aboutus1_second?.width,
    },
    {
      text: (
        <>
          Schetsen van vazen en servies werden levende sculpturen, geboren uit
          het draaien van de pottenbakkersschijf. Het was niet alleen een
          transitie; het was een ontmoeting, een samenvloeien van mijn reis met
          de eindeloze mogelijkheden die klei te bieden heeft. In de wereld van
          keramiek vond ik een nieuwe dimensie van creativiteit. Elke aanraking,
          elke draai, vertelde een verhaal van transformatie.
          <br />
          <br />
          Klei, als geschenk van de natuur, elke aanraking voelt als een
          samenspel met de natuur, een eerbetoon aan de eenvoud van het
          materiaal. Het proces, van een simpel hoopje klei tot iets tastbaar,
          heeft iets magisch.
        </>
      ),
      src: imageProps?.aboutus2?.src,
      src2: imageProps?.aboutus2_second?.src,
      alt: imageProps?.aboutus2?.alt,
      alt2: imageProps?.aboutus2_second?.alt,
      height: imageProps?.aboutus2?.height,
      height2: imageProps?.aboutus2_second?.height,
      width: imageProps?.aboutus2?.width,
      width2: imageProps?.aboutus2_second?.width,
    },
    {
      isLastItem: true,
      text: (
        <>
          Bij Ñezceramics ligt de nadruk op het creëren van unieke
          keramiekstukken. Elk stuk wordt met zorg en precisie vervaardigd, met
          oog voor detail en gebruikmakend van zorgvuldig geselecteerde
          materialen. Esthetiek is voor mij een constante drijfveer.
          <br />
          <br />
          Bij elk gevormd stuk zoek ik naar de juiste mix van bruikbaarheid en
          subtiele elegantie. Elk stuk vertelt ook een verhaal. Dit is niet
          zomaar een verzameling keramiek; het is een stukje van mijn hart,
          gevormd door mijn passie voor de kracht van klei, de kunst van
          pottendraaien en de voortdurende zoektocht naar verfijning.
          <br />
          <br />
          Ik nodig je uit om samen met mij deze reis te maken, waar fotografie,
          schetsen, tekenen en schilderen een nieuwe plek hebben gevonden in het
          creatieve proces van het maken van keramiek.
        </>
      ),
      src: imageProps?.aboutus3?.src,
      src2: imageProps?.aboutus3_second?.src,
      alt: imageProps?.aboutus3?.alt,
      alt2: imageProps?.aboutus3_second?.alt,
      height: imageProps?.aboutus3?.height,
      height2: imageProps?.aboutus3_second?.height,
      width: imageProps?.aboutus3?.width,
      width2: imageProps?.aboutus3_second?.width,
    },
  ];
};
