import React from "react";
import { Route, Routes, useLocation, Outlet } from "react-router-dom";

import Navigation from "../components/navbar/customNavbar";
import Footer from "../components/footer";
import ContentWrapper from "../components/layout/contentWrapper";
import { paths } from "../constants/paths";
import Home from "../pages/home";
import About from "../pages/about";
import NotFound from "../pages/notFound";
import Login from "../pages/login";
import Contact from "../pages/contact";
import CoockiePolicy from "../pages/coockiePolicy";
import GeneralConditions from "../pages/generalConditions";
import Collections from "../pages/collections";
import LegalNotice from "../pages/legalNotice";
import CollectionItems from "../pages/collectionItems";
import FAQ from "../pages/faq";

export default function RouterNavigation() {
  const location = useLocation();

  return (
    <Routes>
      <Route
        element={
          <NavigationRoutes
            location={location}
            cupsFooter={enableCupsFooter(location)}
            smallFooter={enableSmallFooter(location, paths)}
            aboutFooter={enableAboutFooter(location, paths)}
            noFooter={disableFooter(location, paths)}
          />
        }
      >
        <Route path={paths?.index} element={<Home />} />
        <Route path={paths?.about} element={<About />} />
        <Route path={paths?.contact} element={<Contact />} />
        <Route path={paths?.faq} element={<FAQ />} />
        <Route path={paths?.collections} element={<Collections />} />
        <Route path={paths?.collectionSubcats} element={<CollectionItems />} />
        <Route path={paths?.collectionSubCatsItem} element={<Collections />} />
        <Route path={paths?.login} element={<Login />} />
        <Route
          path={paths?.generalConditions}
          element={<GeneralConditions />}
        />
        <Route path={paths?.legalNotice} element={<LegalNotice />} />
        <Route path={paths?.coockiePolicy} element={<CoockiePolicy />} />
        <Route path={paths?.notFound} element={<NotFound />} />
        <Route
          path={paths?.unauthorized}
          element={<NotFound unauthorized={true} />}
        />
      </Route>
    </Routes>
  );
}

function NavigationRoutes({
  location,
  noFooter,
  smallFooter,
  aboutFooter,
  cupsFooter,
}) {
  return (
    <>
      <Navigation location={location} />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
      {(!noFooter || smallFooter) && (
        <Footer
          smallFooter={smallFooter}
          aboutFooter={aboutFooter}
          cupsFooter={cupsFooter}
        />
      )}
    </>
  );
}

const disableFooter = (location, paths) => {
  const isLogin = location?.pathname === paths?.login;
  const isCart = location?.pathname === paths?.cart;

  if (isLogin || isCart) {
    return true;
  }

  return false;
};

const enableSmallFooter = (location, paths) => {
  const isLogin = location?.pathname === paths?.login;

  if (isLogin) {
    return true;
  }

  return false;
};

const enableAboutFooter = (location, paths) => {
  const isLogin = location?.pathname === paths?.about;

  if (isLogin) {
    return true;
  }

  return false;
};

const enableCupsFooter = (location) => {
  const isCupsPath = location?.pathname === "/collections/cups";

  if (isCupsPath) {
    return true;
  }

  return false;
};
