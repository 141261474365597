import React from "react";
import { Container } from "react-bootstrap";
import SectionTitle from "../components/titles/sectionTitle";

export default function GeneralConditions() {
  return (
    <Container>
      <section className="custom_section">
        <div className="d-flex flex-row justify-content-center">
          <SectionTitle title={"Algemene voorwaarden"} />
        </div>
        <div className="custom_section_top card_text">
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 1. IDENTITEIT VAN DE VERKOPER
          </h4>
          <div>ÑEZCERAMICS</div>
          <div>Inez De Clerck</div>
          <div>Chaussée de La Libération 15</div>
          <div>7750 Orroir</div>
          <div>België</div>
          <div>Email: info@nezceramics.be</div>
          <div>BTW-nummer: BE1003.595.553</div>
          <div>IBAN: BE0800196643813</div>
          <p>BIC: GEBABEBB</p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 2. ALGEMENE VOORWAARDEN
          </h4>
          <div className="card_text">
            <p>
              2.1 Deze algemene voorwaarden zijn van toepassing op alle
              aanbiedingen van ÑEZCERAMICS.
            </p>
            <p>
              De voorwaarden zijn voor eenieder toegankelijk en opgenomen op de
              website https://www.nezceramics.be/
            </p>
            <p>
              2.2 Door het plaatsen van een bestelling geeft u te kennen dat u
              met de leverings- en betalingsvoorwaarden akkoord gaat.
              ÑEZCERAMICS behoudt zich het recht voor haar leverings- en/of
              betalingsvoorwaarden na het verstrijken van de looptijd te
              wijzigen.
            </p>
            <p>
              2.3 Tenzij schriftelijk anders is overeengekomen, worden de
              algemene of specifieke voorwaarden of bedingen van derden niet
              door ÑEZCERAMICS erkend.
            </p>
          </div>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 3. PRIJZEN
          </h4>
          <div className="card_text">
            <p>
              3.1 Alle vermelde prijzen zijn uitgedrukt in EURO, steeds
              inclusief BTW en alle andere verplicht door de Klant te dragen
              taksen of belastingen.
            </p>
            <p>
              3.2 Indien leverings-, reservatie- of administratieve kosten
              worden aangerekend, wordt dit apart vermeld.
            </p>
            <p>
              3.3 De opgave van prijs slaat uitsluitend op de artikelen zoals
              het woordelijk wordt omschreven. De bijhorende foto’s zijn
              decoratief bedoeld en kunnen elementen bevatten die niet
              inbegrepen zijn in de prijs.
            </p>
          </div>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 4. AANBOD
          </h4>
          <p>
            4.1 Ondanks het feit dat de online catalogus en de e-commerce
            website met de grootst mogelijke zorgvuldigheid worden samengesteld,
            is het toch mogelijk dat de aangeboden informatie onvolledig is,
            materiële fouten bevat, of niet up-to-date is. Kennelijke
            vergissingen of fouten in het aanbod binden ÑEZCERAMICS niet.
            ÑEZCERAMICS is wat de juistheid en volledigheid van de aangeboden
            informatie slechts gehouden tot een middelenverbintenis. ÑEZCERAMICS
            maakt uitsluitend artisanale, handgemaakte objecten en zijn dan ook
            duidelijk verschillend van elkaar. De gebruikte foto’s van de
            website zijn slechts voorbeelden want elk product is door zijn
            ‘handmade’ verschillend. Dit is ons concept. ÑEZCERAMICS is in geen
            geval aansprakelijk ingeval van manifeste materiële fouten, zet- of
            drukfouten.
          </p>
          <p>
            4.2 Wanneer de Klant specifieke vragen heeft over bv. maten, kleur,
            verschillen, structuren, glazuren beschikbaarheid, leveringstermijn
            of leveringswijze, verzoeken wij de Klant om vooraf contact op te
            nemen via e-mail.
          </p>
          <p>
            4.3 Het aanbod geldt steeds zolang de voorraad strekt en kan te
            allen tijde worden aangepast of ingetrokken door ÑEZCERAMICS.
            ÑEZCERAMICS kan niet aansprakelijk gesteld worden voor het niet
            beschikbaar zijn van een product. Indien een aanbod een beperkte
            geldigheidsduur heeft of onder voorwaarden geschiedt, wordt dit
            nadrukkelijk in het aanbod vermeld.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 5: AANKOPEN VIA DE WEBSITE ZELF
          </h4>
          <p>
            5.1 De Klant die zijn aankoop via de website zelf doet, dient bij de
            aankoop van ‘vaste producten’ – dit zijn producten die standaard te
            koop worden aangeboden door ÑEZCERAMICS – 20% te betalen op het
            moment van de bestelling. ÑEZCERAMICS zal hiertoe een
            betalingsuitnodiging overmaken aan de Klant.
          </p>
          <p>
            De Klant die zijn aankoop via de website zelf doet, dient bij de
            aankoop van ‘aangepaste producten’ – dit zijn producten die
            specifiek worden gemaakt op basis van de wensen van de Klant – 20%
            te betalen na ontvangst door ÑEZCERAMICS van de door de Klant
            ondertekende offerte. ÑEZCERAMICS zal hiertoe een
            betalingsuitnodiging overmaken aan de Klant.
          </p>
          <p>
            5.2 Het saldo van de aankoop die via de website zelf gebeurt, dient
            voldaan te worden op het moment van de levering van het product. De
            saldofactuur zal samen met het bestelde product aan de Klant worden
            bezorgd.
          </p>
          <p>
            5.3 Duidelijk wordt aangegeven dat het product dat de Klant wenst
            aan te kopen met de hand gemaakt is en duidelijk kan verschillen met
            de foto. Het concept van ÑEZCERAMICS gaat over het imperfecte
            product.
          </p>
          <p>
            5.3 Duidelijk wordt aangegeven dat het product dat de Klant wenst
            aan te kopen met de hand gemaakt is en duidelijk kan verschillen met
            de foto. Het concept van ÑEZCERAMICS gaat over het imperfecte
            product.
          </p>
          <p>
            5.4 ÑEZCERAMICS is gerechtigd een bestelling te weigeren ingevolge
            een ernstige tekortkoming van de Klant met betrekking tot
            bestellingen waarbij de Klant betrokken is.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 6: AANKOPEN VIA DE WEBSHOP
          </h4>
          <p>
            6.1 De Klant doet zijn aankoop via onze website en komt dan terecht
            in ons winkelmandje waar hij de bestelling kan afronden met de
            betalingswijze.
          </p>
          <p>
            6.2 Duidelijk wordt aangegeven dat het product dat de Klant wenst
            aan te kopen met de hand gemaakt is en duidelijk kan verschillen met
            de foto. Het concept van ÑEZCERAMICS gaat over het imperfecte
            product.
          </p>
          <p>
            6.3 De Klant heeft de keuze tussen de volgende betaalwijzen via
            kredietkaart:
          </p>
          <ul>
            <li>Bancontact</li>
            <li>KBC Payment</li>
            <li>ING Home’Pay</li>
            <li>BELFIUS direct net</li>
            <li>iDEAL</li>
            <li>PayPal</li>
          </ul>
          <p>
            6.4 ÑEZCERAMICS is gerechtigd een bestelling te weigeren ingevolge
            een ernstige tekortkoming van de Klant met betrekking tot
            bestellingen waarbij de Klant betrokken is.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 7: LEVERING EN UITVOERING VAN DE OVEREENKOMST
          </h4>
          <p>
            7.1 Tenzij anders overeengekomen of uitdrukkelijk anders bepaald,
            worden de goederen aan de woonplaats van de Klant geleverd binnen de
            10 weken na ontvangst van de bestelling.
          </p>
          <p>
            7.2 Elke zichtbare beschadiging en/of kwalitatieve tekortkoming van
            een artikel of andere tekortkoming bij de levering, moeten door de
            Klant onverwijld worden gemeld aan ÑEZCERAMICS per mail:
            info@nezceramics.be.
          </p>
          <p>
            7.3 Het risico wegens verlies of beschadiging gaat over op de Klant
            vanaf hij (of een door hem aangewezen derde partij, die niet de
            vervoerder is) de goederen fysiek in bezit heeft gekregen. Het
            risico gaat echter al over op de Klant bij levering aan de
            vervoerder, als de vervoerder van de Klant de opdracht heeft
            gekregen de goederen te vervoeren en deze keuze niet aan de Klant
            was geboden.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 8: EIGENDOMSVOORBEHOUD
          </h4>
          <p>
            8.1 De geleverde artikelen blijven tot op het moment van gehele
            betaling door de Klant, de exclusieve eigendom van ÑEZCERAMICS.
          </p>
          <p>
            8.2 De Klant verbindt er zich toe zo nodig derden op het
            eigendomsvoorbehoud van ÑEZCERAMICS te wijzen, bv. aan eenieder die
            op de nog niet geheel betaalde artikelen beslag zou komen leggen.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 9: HERROEPINGSRECHT
          </h4>
          <p>
            9.1 De bepalingen van dit artikel gelden enkel voor Klanten die in
            hun hoedanigheid van consument artikelen online aankopen bij
            ÑEZCERAMICS.
          </p>
          <p>
            9.2 De Klant heeft het recht om binnen een termijn van 14
            kalenderdagen zonder opgave van redenen de overeenkomst te
            herroepen.
          </p>
          <p>
            De herroepingstermijn verstrijkt 14 kalenderdagen na de dag "van de
            sluiting van de overeenkomst"
          </p>
          <p>
            9.3 Om het herroepingsrecht uit te oefenen, moet de Klant
            ÑEZCERAMICS, Chaussée de La Libération 15, 7750 Orroir,
            info@nezceramics.be via een ondubbelzinnige verklaring (bv.
            schriftelijk per post of per e-mail) op de hoogte stellen van zijn
            beslissing de overeenkomst te herroepen.
          </p>
          <p>
            9.4 De Klant moet de goederen onverwijld, doch in ieder geval niet
            later dan 14 kalenderdagen na de dag waarop hij zijn beslissing om
            de overeenkomst te herroepen aan ÑEZCERAMICS heeft meegedeeld,
            terugzenden of overhandigen aan ÑEZCERAMICS, Chaussée de La
            Libération. De Klant is op tijd als hij de goederen terugstuurt
            voordat de termijn van 14 kalenderdagen is verstreken.
          </p>
          <p>
            9.5 De directe kosten van het terugzenden van de goederen komen voor
            rekening van de Klant.
          </p>
          <p>
            9.6 De Klant wordt verzocht de artikelen terug te sturen in hun
            oorspronkelijke staat en verpakking, met alle erbij geleverde
            accessoires, en gebruiksaanwijzingen. Indien het teruggestuurde
            product op een of andere manier in waarde verminderd is, behoudt
            ÑEZCERAMICS zich het recht voor om de Klant aansprakelijk te stellen
            en schadevergoeding te eisen voor elke waardevermindering van de
            goederen die het gevolg is van het gebruik van de goederen door de
            Klant dat verder gaat dan nodig is om de aard, de kenmerken en de
            werking van de goederen vast te stellen.
          </p>
          <p>
            9.7 Alle teruggestuurde artikelen worden aandachtig onderzocht. Het
            uitgangspunt hierbij is dat de Klant het artikel slechts mag
            inspecteren zoals hij dat in een winkel zou mogen doen. Wanneer een
            artikel een waardevermindering heeft ondergaan door het gebruik van
            de Klant zal dit ten laste van de Klant in rekening worden gebracht.
          </p>
          <p>
            9.8 Indien de Klant de overeenkomst herroept, zal ÑEZCERAMICS alle
            tot op dat moment van de Klant ontvangen betalingen, inclusief de
            standaard leveringskosten, aan de Klant terugbetalen binnen maximum
            14 kalenderdagen nadat ÑEZCERAMICS op de hoogte is gesteld van de
            beslissing van de Klant om de overeenkomst te herroepen. Bij
            verkoopovereenkomsten kan ÑEZCERAMICS wachten
          </p>
          <p>
            met de terugbetaling totdat hij alle goederen heeft teruggekregen,
            of totdat de Klant heeft aangetoond dat hij de goederen heeft
            teruggezonden, naar gelang welk tijdstip eerst valt.
          </p>
          <p>
            9.9 Eventuele extra kosten ten gevolge van de keuze van de Klant
            voor een andere wijze van levering dan de door ÑEZCERAMICS geboden
            goedkoopste standaard levering worden niet terugbetaald.
          </p>
          <p>
            9.10 ÑEZCERAMICS betaalt de Klant terug met hetzelfde betaalmiddel
            als waarmee de Klant de oorspronkelijke transactie heeft verricht,
            tenzij de Klant uitdrukkelijk anderszins heeft ingestemd; in ieder
            geval zullen de Klant voor zulke terugbetaling geen kosten in
            rekening worden gebracht.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 10: GARANTIE
          </h4>
          <p>
            10.1 Krachtens de wet van 21 september 2004 betreffende de
            bescherming van de consumenten bij verkoop van consumptiegoederen
            heeft de Klant wettelijke rechten. Deze wettelijke garantie geldt
            vanaf de datum van levering aan de eerste eigenaar. Elke commerciële
            garantie laat deze rechten onverminderd.
          </p>
          <p>
            10.2 Om een beroep te doen op de garantie, moet de Klant een
            aankoopbewijs kunnen voorleggen. Klanten wordt aangeraden om de
            oorspronkelijke verpakking van de goederen te bewaren.
          </p>
          <p>
            10.3 Voor artikelen die werden aangekocht en bij de Klant thuis zijn
            afgeleverd, dient de Klant contact op te nemen met ÑEZCERAMICS en
            het artikel op zijn kosten terug te bezorgen aan ÑEZCERAMICS.
          </p>
          <p>
            10.4 Bij vaststelling van een gebrek moet de Klant ÑEZCERAMICS zo
            snel mogelijk inlichten. In ieder geval dient elk gebrek binnen een
            termijn van 14 dagen na vaststelling ervan door de Klant te worden
            gemeld. Nadien vervalt elk recht op herstelling of vervanging.
          </p>
          <p>
            10.5 De (commerciële en/of wettelijke) garantie is nooit van
            toepassing op defecten die ontstaan ten gevolge van ongelukken,
            verwaarlozing, valpartijen, gebruik van het artikel in strijd met
            doel waarvoor het ontworpen werd, het niet naleven van de
            gebruiksinstructies of handleiding, aanpassingen of wijzigingen aan
            het artikel, hardhandig gebruik, slecht onderhoud, of elk ander
            abnormaal of incorrect gebruik.
          </p>
          <p>
            10.6 Defecten die zich manifesteren na een periode van 2 maanden
            volgend op datum van aankoop, desgevallend levering, worden geacht
            geen verborgen gebreken te zijn, behoudens tegenbewijs door de
            Klant.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 11: SANCTIES VOOR NIET-BETALING
          </h4>
          <p>
            Na schriftelijke aanmaning tot betaling van de openstaande factuur
            en bij gebrek aan terecht bevonden protest, is de Klant op het
            openstaande saldo, een rente t.b.v. de wettelijke intrestvoet
            verschuldigd na het verstrijken van een periode van 14 dagen tot aan
            de datum van de algehele betaling. Deze periode van 14 dagen begint
            te lopen op de derde dag na het versturen van de schriftelijke
            aanmaning.
          </p>
          <p>
            Zo de aanmaning elektronisch verstuurd wordt, start de termijn van
            14 dagen op de dag die volgt op de dag waarop de elektronisch
            aanmaning verstuurd is.
          </p>
          <p>
            Voorts zal er een schadebeding a rato van 10% verschuldigd zijn.
          </p>
          <p>Alle invorderingskosten zijn integraal ten laste van de klant.</p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 12: PRIVACY
          </h4>
          <p>
            12.1 In het kader van de opdracht die u wenst toe te vertrouwen aan
            ÑEZCERAMICS, dient het een zekere hoeveelheid informatie over u te
            verwerken. Deze informatie is noodzakelijk om de opdracht die u aan
            ÑEZCERAMICS toevertrouwt, te vervullen.
          </p>
          <p>
            12.2 In de mate dat deze informatie rechtstreeks of onrechtstreeks
            betrekking heeft op u als natuurlijke persoon (in dat geval spreken
            we over persoonsgegevens) respecteert ÑEZCERAMICS de verplichtingen
            van de wetgeving inzake de bescherming van de
            persoonsgegevenswetgeving.
          </p>
          <p>
            Dit houdt onder meer in dat ÑEZCERAMICS deze gegevens enkel zal
            verwerken voor de opdracht die u aan ÑEZCERAMICS heeft toevertrouwd.
            In die context is het verwerken van persoonsgegevens door
            ÑEZCERAMICS ook toegelaten (zie artikel 6.1.b van de Verordening
            2016/679).
          </p>
          <p>
            Uw gegevens kunnen ook worden gebruikt voor doeleinden die
            onlosmakelijk verbonden zijn met de opdracht die u aan ÑEZCERAMICS
            heeft toevertrouwd (zoals bij voorbeeld facturatie).
          </p>
          <p>
            12.3 De verzamelde gegevens zijn minimaal uw identiteitsgegevens, uw
            correspondentie – en factuuradres evenals alle gegevens die strikt
            noodzakelijk zijn voor de uitvoeren van de opdracht die u
            ÑEZCERAMICS toevertrouwt
          </p>
          <p>
            12.4 De persoonsgegevens van de Klant worden bewaard voor de duur
            van het behandelen van uw opdracht.
          </p>
          <p>
            12.5 Uw gegevens worden gearchiveerd op het ogenblik dat de opdracht
            die u ons toevertrouwd heeft, is vervuld en alle betalingen zijn
            verricht. Na archivering wordt alle informatie die over u in het
            kader van de aan ÑEZCERAMICS toevertrouwde opdracht werd verwerkt,
            nog 10 jaar bewaard (met uitzondering van de regularisatiedossiers
            die 15 jaar worden bewaard), nadien worden uw gegevens
            geanonimiseerd op de digitale systemen die zich bij ÑEZCERAMICS
            bevinden.
          </p>
          <p>
            12.6 U heeft als betrokkene steeds het recht om uw persoonsgegevens
            in te zien en te verzoeken om rectificatie, wissen of beperking van
            de verwerking van de u betreffende gegevens.
          </p>
          <p>
            U heeft tevens het recht tegen een verwerking bezwaar te maken en
            het recht op gegevensoverdraagbaarheid.
          </p>
          <p>
            U kunt zich voor de uitoefening van deze rechten wenden tot mevrouw
            Inez De Clerck op het e-mailadres info@nezceramics.be.
          </p>
          <p>
            12.7 U heeft als betrokkene ook steeds het recht klacht in te dienen
            bij de Belgische Gegevensbeschermingsautoriteit.
          </p>
          <p>
            12.8 De klant is zelf verantwoordelijk voor het vertrouwelijk houden
            van zijn logingegevens en het gebruik van zijn paswoord. Uw paswoord
            wordt gecodeerd opgeslagen, ÑEZCERAMICS heeft dus geen toegang tot
            uw paswoord.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 13: GEBRUIK VAN COOKIES
          </h4>
          <p>
            13.1 Tijdens een bezoek aan de site kunnen 'cookies' op de harde
            schijf van uw computer geplaatst worden. Een cookie is een
            tekstbestand dat door de server van een website in de browser van uw
            computer of op uw mobiel apparaat geplaatst wordt wanneer u een
            website raadpleegt. Cookies kunnen niet worden gebruikt om personen
            te identificeren, een cookie kan slechts een machine identificeren.
          </p>
          <p>
            13.2 U kan uw internetbrowser zodanig instellen dat cookies niet
            worden geaccepteerd, dat u een waarschuwing ontvangt wanneer een
            cookie geïnstalleerd wordt of dat de cookies nadien van uw harde
            schijf worden verwijderd. Dit kan u doen via de instellingen van uw
            browser (via de help-functie). Hou er hierbij wel rekening mee dat
            bepaalde grafische elementen niet correct kunnen verschijnen, of dat
            u bepaalde applicaties niet zal kunnen gebruiken.
          </p>
          <p>
            13.3 Door gebruik te maken van onze website, gaat u akkoord met ons
            gebruik van cookies.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 14: AANTASTING GELDIGHEID - NIET-VERZAKING
          </h4>
          <p>
            14.1 Indien een bepaling van deze Voorwaarden ongeldig, onwettig of
            nietig wordt verklaard, zal dit op geen enkele wijze de geldigheid,
            de wettigheid en de toepasbaarheid van de andere bepalingen
            aantasten.
          </p>
          <p>
            14.2 Het nalaten op gelijk welk moment door ÑEZCERAMICS om één van
            de in deze Voorwaarden opgesomde rechten af te dwingen, of gelijk
            welk recht hiervan uit te oefenen, zal nooit gezien worden als een
            verzaking aan zulke bepaling en zal nooit de geldigheid van deze
            rechten aantasten.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 15: WIJZIGING VOORWAARDEN
          </h4>
          <p>
            Deze Voorwaarden worden aangevuld door andere voorwaarden waar
            expliciet naar verwezen wordt, en de algemene verkoopsvoorwaarden
            van ÑEZCERAMICS. Ingeval van tegenstrijdigheid, primeren onderhavige
            Voorwaarden.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 16: BEWIJS
          </h4>
          <p>
            De Klant aanvaardt dat elektronische communicaties en back-ups als
            bewijsvoering kunnen dienen.
          </p>
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 17: TOEPASSELIJK RECHT – GESCHILLEN
          </h4>
          <p>Het Belgisch recht is van toepassing.</p>
          <p>
            Alleen de rechtbanken die territoriaal bevoegd zijn voor de plaats
            waar de zetel van ÑEZCERAMICS gelegen is, zijn bevoegd om te
            oordelen over de geschillen die ontstaan in uitvoering van de
            opdracht.
          </p>
        </div>
      </section>
    </Container>
  );
}
