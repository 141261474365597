import React from "react";
import { Helmet } from "react-helmet";

import FAQContent from "../components/content/faqContent";

export default function FAQ() {
  return (
    <>
      <Helmet>
        <title>Frequently asked questions | Ñezceramics</title>
        <meta
          name="description"
          content="Neem contact op met Ñezceramics Chausée de la Libération 15, 7750 Mont-de-l'Enclus Mont-de-l'Enclus (kluisbergen, orroir)"
        />
        <meta property="og:title" content="FAQ - Ñezceramics" />
        <meta
          property="og:description"
          content="Neem contact op met Ñezceramics Chaussée de la Libération 15, 7750 Mont-de-l'Enclus Mont-de-l'Enclus (kluisbergen, orroir)"
        />
        <meta property="og:url" content="https://nezceramics.be/faq" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://nezceramics.be/faq" />
      </Helmet>
      <FAQContent />
    </>
  );
}
