import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Preloader from "./components/loaders/preloader";
import { paths } from "./constants/paths";
import App from "./App";
import { CoockieContainer } from "./components/coockies/coockieContainer";
import { NotificationContainer } from "./components/notifications";
import { ProvideFirestore } from "./authentication/authContext";

const root = ReactDOM.createRoot(document.getElementById("App"));

root.render(
  <ProvideFirestore>
    <Router basename={paths?.index}>
      <Preloader content={<App />} />
      <CoockieContainer />
      <NotificationContainer />
    </Router>
  </ProvideFirestore>
);
