import * as Yup from "yup";

export const contactValidation = () => {
  return Yup.object().shape({
    name: Yup.string().required("Naam is verplicht"),
    email: Yup.string()
      .email("Ongeldig e-mailadres")
      .required("E-mail is verplicht"),
    message: Yup.string().required("Bericht is verplicht"),
  });
};
