import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotificationContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      pauseOnFocusLoss={false}
      hideProgressBar={false}
      autoClose={2500}
      closeOnClick
      newestOnTop
      draggable
    />
  );
};

export function SetNotifciation({ type, text }) {
  switch (type) {
    case "error":
      return toast.error(text);
    case "success":
      return toast.success(text);
    case "warn":
      return toast.warn(text);
    case "info":
      return toast.info(text);
    default:
      return toast.info(text);
  }
}
