import React from "react";
import { Helmet } from "react-helmet";

import ContactContent from "../components/content/contactContent";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact | Ñezceramics</title>
        <meta
          name="description"
          content="Neem contact op met Ñezceramics Chausée de la Libération 15, 7750 Mont-de-l'Enclus Mont-de-l'Enclus (kluisbergen, orroir)"
        />
        <meta property="og:title" content="Contact - Ñezceramics" />
        <meta
          property="og:description"
          content="Neem contact op met Ñezceramics Chaussée de la Libération 15, 7750 Mont-de-l'Enclus Mont-de-l'Enclus (kluisbergen, orroir)"
        />
        <meta property="og:url" content="https://nezceramics.be/contact" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://nezceramics.be/contact" />
      </Helmet>
      <ContactContent />
    </>
  );
}
