import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "350px",
};

const center = {
  lat: 50.75054,
  lng: 3.47277,
};

const mapStyles = [
  {
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
];

export default function GoogleMaps() {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(() => {
    const bounds = new window.google.maps.LatLngBounds();
    map?.fitBounds(bounds);
    setMap(map);
  }, [map]);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return (
    isLoaded &&
    !loadError && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{ styles: mapStyles }}
      >
        <Marker position={center} />
      </GoogleMap>
    )
  );
}
