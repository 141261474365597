import React from "react";
import { Helmet } from "react-helmet";

import CustomCarousel from "../components/carousel/customCarousel";
import AboutUsContent from "../components/content/aboutUsContent";
import { carouselID } from "../constants/carouselID";

export default function About() {
  return (
    <>
      <Helmet>
        <title>Over Ñez | Ñezceramics</title>
        <meta
          name="description"
          content="Ontdek Ñezceramics: een plek waar passie voor keramiek, grafisch ontwerp, en de natuur samenkomen. Elk stuk weerspiegelt een uniek verhaal van creativiteit en finesse, zorgvuldig gevormd in ons atelier."
        />
        <meta property="og:title" content="Over Ñez - Ñezceramics" />
        <meta
          property="og:description"
          content="Ontdek Ñezceramics: een plek waar passie voor keramiek, grafisch ontwerp, en de natuur samenkomen. Elk stuk weerspiegelt een uniek verhaal van creativiteit en finesse, zorgvuldig gevormd in ons atelier."
        />
        <meta property="og:url" content="https://nezceramics.be/about" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://nezceramics.be/about" />
      </Helmet>
      <CustomCarousel id={carouselID?.about} />
      <AboutUsContent />
    </>
  );
}
