export const paths = {
  index: "/",
  notFound: "*",
  about: "/about",
  contact: "/contact",
  login: "/login",
  collections: "/collections",
  faq: "/faq",
  collectionSubcats: "/collections/:collectionName",
  collectionSubCatsItem: "/collections/:collectionName/:itemId",
  account: "/account",
  generalConditions: "/general-conditions",
  legalNotice: "/legal-notice",
  coockiePolicy: "/coockie-policy",
  paymentPolicy: "/payment-policy",
  unauthorized: "/unauthorized",
};
