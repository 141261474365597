import React from "react";
import { Field, ErrorMessage } from "formik";
import { Form } from "react-bootstrap";

export function TextInput({ name, placeholder, isEmail }) {
  return (
    <div className="form-group mb-4">
      <Field
        as={Form.Control}
        type={isEmail ? "email" : "text"}
        name={name}
        placeholder={placeholder}
      />
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
}

export function TextArea({ name, placeholder, rows }) {
  return (
    <div className="form-group mb-4">
      <Field
        as="textarea"
        name={name}
        placeholder={placeholder}
        rows={rows}
        className="form-control"
      />
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
}
