import React from "react";
import { Card, Col, Container, Placeholder, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useFirestore } from "../../authentication/authContext";
import ProgressiveImage from "../images/progressiveImage";
import { CustomSectionTitle } from "../titles/sectionTitle";

export default function CollectionsContent() {
  const { collections, loading } = useFirestore();

  return (
    <Container>
      <section className="custom_section">
        <div className="">
          <CustomSectionTitle title="Collecties" />
        </div>
        <div className={"custom_section_top"}>
          <CollectionCards collections={collections} loading={loading} />
        </div>
      </section>
    </Container>
  );
}

function CollectionCards({ collections, loading }) {
  const placeholderItems = Array.from({ length: collections?.length || 4 });

  const renderPlaceholderCard = (_, index) => (
    <React.Fragment key={index}>
      <Col>
        <Card>
          <div className="img_placeholder_container">
            <Placeholder animation="glow" className="img_placeholder" />
          </div>
          <Card.Body>
            <Placeholder
              as={Card.Title}
              animation="glow"
              className="text-center card_title mb-0"
            >
              <Placeholder xs={6} />
            </Placeholder>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );

  const renderCollectionCard = (item, index) => (
    <Col key={index}>
      <Card>
        <Link
          className="no_style_button"
          to={`/collections/${encodeURIComponent(item?.title?.toLowerCase())}`}
        >
          <ProgressiveImage
            src={item?.src}
            alt={`collection: ${item?.title}`}
            className="img-fluid"
          />
          <Card.Body>
            <Card.Title className="text-center card_title mb-0">
              {item?.title}
            </Card.Title>
          </Card.Body>
        </Link>
      </Card>
    </Col>
  );

  return (
    <Row xs={1} md={2} lg={3} xl={4} className="g-3">
      {loading
        ? placeholderItems?.map(renderPlaceholderCard)
        : collections?.map(renderCollectionCard)}
    </Row>
  );
}
