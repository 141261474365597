import React from "react";
import { Container } from "react-bootstrap";

import { Features_ARRAY } from "../../constants/arrays";
import HorizontalCard from "../cards/horizontalCard";
import ProgressiveImage from "../images/progressiveImage";

export default function FeaturesContent() {
  const featuresArr = Features_ARRAY();

  return (
    <Container>
      <section className="custom_section">
        <FeaturesCards array={featuresArr} />
      </section>
    </Container>
  );
}

function FeaturesCards({ array }) {
  return array?.map((item, index) => (
    <React.Fragment key={index}>
      <HorizontalCard
        isFirstItem={item?.isFirstItem}
        isLeftImg={index % 2 !== 0}
        isLastItem={item?.isLastItem}
        title={item?.title}
        text={item?.text}
        img={
          <ProgressiveImage
            src={item?.src}
            alt={item?.alt}
            height={item?.height}
            width={item?.width}
            className="img-fluid features_image "
          />
        }
        img2={
          item?.src2 && (
            <ProgressiveImage
              src={item?.src2}
              alt={item?.alt2}
              height={item?.height2}
              width={item?.width2}
              className="img-fluid features_image"
            />
          )
        }
      />
    </React.Fragment>
  ));
}
