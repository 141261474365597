import React from "react";
import { Formik } from "formik";
import { Row, Col, Form } from "react-bootstrap";

import { TextArea, TextInput } from "../inputs/formInput";
import LoadingButton from "../buttons/loadingButton";
import { contactValidation } from "../../constants/validations";
import SendEmail from "../../functions/email";
import { SetNotifciation } from "../notifications";

export default function ContactForm() {
  const initValues = { name: "", email: "", company: "", message: "" };

  const handleSubmit = (values, actions) => {
    SendEmail({
      params: values,
      onSuccess: () => {
        SetNotifciation({
          type: "success",
          text: "Uw bericht werd verzonden",
        });
        actions.setSubmitting(false);
        actions.resetForm();
      },
      onError: () => {
        SetNotifciation({
          type: "error",
          text: "Failed to reach host: contact development for more info",
        });
        actions.setSubmitting(false);
      },
    });
  };

  return (
    <Formik
      initialValues={initValues}
      validationSchema={contactValidation()}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form onSubmit={props?.handleSubmit}>
          <Row>
            <Col md={6}>
              <TextInput name="name" placeholder="Naam" />
            </Col>
            <Col md={6}>
              <TextInput name="email" placeholder="E-mail" />
            </Col>
          </Row>
          <Col>
            <TextInput name="company" placeholder="Bedrijf" />
          </Col>
          <Col>
            <TextArea name="message" rows="5" placeholder="Bericht" />
          </Col>
          <LoadingButton
            className="btn secundairy_button"
            text={"Verzenden"}
            isLoading={props?.isSubmitting}
            type="submit"
          />
        </Form>
      )}
    </Formik>
  );
}
