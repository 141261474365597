import React from "react";
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";

export function CoockieContainer() {
  return (
    <CookieConsent
      overlay
      location={"bottom"}
      buttonText={`Begrepen!`}
      coockieName={"NEZCERAMICS"}
      expires={365}
      style={coockieContainer}
      buttonStyle={cookieButtonStyle}
    >
      {
        "This website uses cookies to ensure you get the best experience on our website. By continuing to use this website, you consent to the use of cookies as described in our Privacy Policy."
      }
    </CookieConsent>
  );
}

export function RemoveCoockie() {
  return resetCookieConsentValue();
}

const coockieContainer = {
  background: "#f0ede5",
  textAlign: "left",
  fontSize: "16px",
  color: "#946434",
};

const cookieButtonStyle = {
  backgroundColor: "#946434",
  letterSpacing: "1.1px",
  textDecoration: "none",
  borderRadius: "10px",
  color: "#f0ede5",
  padding: "8px 12px",
  fontFamily: "Nunito, sans-serif",
};
