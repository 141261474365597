import React, { useMemo } from "react";
import { Accordion, Container, Nav } from "react-bootstrap";

import SectionTitle from "../../components/titles/sectionTitle";
import { NavItem } from "../navbar/customNavbar";
import { FAQ_items_ARRAY, Socialmedia_ARRAY } from "../../constants/arrays";

export default function FAQContent() {
  return (
    <Container>
      <section className="custom_section">
        <div className="d-flex flex-row">
          <SectionTitle title="FAQ" />
        </div>
        <FAQTitle />
        <FAQAccordion />
      </section>
    </Container>
  );
}

function FAQTitle() {
  return (
    <div className="custom_section">
      <h5 className="card_title text-center">
        Heeft u een vraag?
        <br />
        Doorzoek onze veelgestelde vragen voor een snel antwoord.
      </h5>
    </div>
  );
}

function FAQAccordion() {
  const faqItems = FAQ_items_ARRAY();

  return (
    <Accordion
      defaultActiveKey={faqItems.map((_, index) => index.toString())}
      alwaysOpen
      
    >
      {faqItems.map((item, index) => (
        <Accordion.Item eventKey={index.toString()} key={index}>
          <Accordion.Header className="card_title">
            {item.header}
          </Accordion.Header>
          <Accordion.Body className="accordion_text">
            {item.body}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
