import React from "react";
import { Col, Row } from "react-bootstrap";

export default function HorizontalCard({
  title,
  text,
  img,
  img2,
  isLeftImg,
  isLastItem,
}) {
  return (
    <Row className={`${!isLastItem && "horizontal_card_padding"}`}>
      <Col
        xs={{ span: 12, order: 2 }}
        md={{ span: 6, order: !isLeftImg ? 2 : 1 }}
        lg={{ span: 5, order: !isLeftImg ? 2 : 1 }}
        className="d-flex flex-column"
      >
        {img2 && <div className="mb-2">{img2}</div>}
        {img}
      </Col>
      <Col
        className="d-flex flex-column justify-content-center "
        xs={{ span: 12, order: 1 }}
        md={{ span: 6, order: !isLeftImg ? 1 : 2 }}
        lg={{ span: 7, order: !isLeftImg ? 1 : 2 }}
      >
        <div
          className={
            !isLeftImg
              ? "text-right card_text_container"
              : "text-left card_text_container"
          }
        >
          <h2 className="card_title pb-3">{title}</h2>
          <div className="card_text">{text}</div>
        </div>
      </Col>
    </Row>
  );
}
export function HorizontalMultiImageCard({
  title,
  text,
  img,
  img2,
  isLeftImg,
  isLastItem,
}) {
  return (
    <Row className={`${!isLastItem && "horizontal_card_padding"}`} noGutters>
      <Col
        xs={{ span: 12, order: 3 }}
        md={{ span: 3, order: !isLeftImg ? 3 : 1 }}
        lg={{ span: 2, order: !isLeftImg ? 3 : 1 }}
        className="d-flex flex-column"
      >
        {img2}
      </Col>
      <Col
        xs={{ span: 12, order: 2 }}
        md={{ span: 4, order: !isLeftImg ? 2 : 2 }}
        lg={{ span: 4, order: !isLeftImg ? 2 : 2 }}
        className="d-flex flex-column"
      >
        {img}
      </Col>

      <Col
        className="d-flex flex-column justify-content-center"
        xs={{ span: 12, order: 1 }}
        md={{ span: 5, order: !isLeftImg ? 1 : 3 }}
        lg={{ span: 6, order: !isLeftImg ? 1 : 3 }}
      >
        <div
          className={
            !isLeftImg
              ? "text-right card_text_container"
              : "text-left card_text_container"
          }
        >
          <div className="card_text">{text}</div>
        </div>
      </Col>
    </Row>
  );
}
