import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

export default function Preloader({ content }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      clearTimeout(timeout);
      setLoading(false);
    };

    let timeout;

    timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);

    if (document.fonts) {
      document.fonts.ready.then(handleLoad).catch((error) => {
        console.error("Font loading error:", error);
        handleLoad();
      });
    } else {
      handleLoad();
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return loading ? (
    <div className="d-flex justify-content-center align-items-center min-vw-100 min-vh-100 preloader">
      <Spinner className="preloader_spinner" animation="grow" />
    </div>
  ) : (
    content
  );
}
