import React from "react";
import { Container } from "react-bootstrap";
import SectionTitle from "../components/titles/sectionTitle";

export default function CoockiePolicy() {
  return (
    <Container>
      <section className="custom_section">
        <div className="d-flex flex-row justify-content-center">
          <SectionTitle title={"Algemene voorwaarden"} />
        </div>
        <div className="custom_section_top card_text">
          <h4 className="card_title mb-3" id="light_black">
            ARTIKEL 13: GEBRUIK VAN COOKIES
          </h4>
          <p>
            13.1 Tijdens een bezoek aan de site kunnen 'cookies' op de harde
            schijf van uw computer geplaatst worden. Een cookie is een
            tekstbestand dat door de server van een website in de browser van uw
            computer of op uw mobiel apparaat geplaatst wordt wanneer u een
            website raadpleegt. Cookies kunnen niet worden gebruikt om personen
            te identificeren, een cookie kan slechts een machine identificeren.
          </p>
          <p>
            13.2 U kan uw internetbrowser zodanig instellen dat cookies niet
            worden geaccepteerd, dat u een waarschuwing ontvangt wanneer een
            cookie geïnstalleerd wordt of dat de cookies nadien van uw harde
            schijf worden verwijderd. Dit kan u doen via de instellingen van uw
            browser (via de help-functie). Hou er hierbij wel rekening mee dat
            bepaalde grafische elementen niet correct kunnen verschijnen, of dat
            u bepaalde applicaties niet zal kunnen gebruiken.
          </p>
          <p>
            13.3 Door gebruik te maken van onze website, gaat u akkoord met ons
            gebruik van cookies.
          </p>
        </div>
      </section>
    </Container>
  );
}
