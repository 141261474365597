import React from "react";
import { Container } from "react-bootstrap";

import { AboutUs_ARRAY } from "../../constants/arrays";
import { HorizontalMultiImageCard } from "../cards/horizontalCard";
import ProgressiveImage from "../images/progressiveImage";
import SectionTitle from "../titles/sectionTitle";

export default function AboutUsContent() {
  const aboutusArr = AboutUs_ARRAY();

  return (
    <Container>
      <section className="custom_section">
        <div className="d-flex flex-row justify-content-end">
          <SectionTitle title="Over Ñez" />
        </div>
        <div className={"custom_section_top"}>
          <AboutUsCards array={aboutusArr} />
        </div>
      </section>
    </Container>
  );
}

function AboutUsCards({ array }) {
  return array?.map((item, index) => (
    <React.Fragment key={index}>
      <HorizontalMultiImageCard
        isFirstItem={item?.isFirstItem}
        isLeftImg={index % 2 !== 0}
        isLastItem={item?.isLastItem}
        title={item?.title}
        text={item?.text}
        img={
          <ProgressiveImage
            src={item?.src}
            alt={item?.alt}
            height={item?.height}
            width={item?.width}
            className="img-fluid aboutus_image h-100 w-100"
          />
        }
        img2={
          <ProgressiveImage
            src={item?.src2}
            alt={item?.alt2}
            height={item?.height2}
            width={item?.width2}
            className="img-fluid aboutus_image h-100 w-100"
          />
        }
      />
    </React.Fragment>
  ));
}
