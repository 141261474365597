import React from "react";
import {
  Breadcrumb,
  Card,
  Col,
  Container,
  Placeholder,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { useFirestore } from "../../authentication/authContext";
import { paths } from "../../constants/paths";
import { CapitalizeFirstLetter } from "../../functions/general";
import ProgressiveImage from "../images/progressiveImage";
import SectionTitle, { CustomSectionTitle } from "../titles/sectionTitle";

export default function CollectionItemsContent({ collectionName }) {
  const { collections, loading } = useFirestore();

  const subcollection = collections?.find(
    (i) => i.title?.toLowerCase() === collectionName?.toLowerCase()
  )?.subcollections;

  const topContent = subcollection?.[0]?.topContent;
  const cardItems = subcollection?.[1]?.items;

  return (
    <Container>
      <section className="custom_section">
        <CustomSectionTitle title={collectionName} />
        <div className={"custom_section"}>
          <CollectionsTopContent topContent={topContent} />
        </div>
        <div className="d-flex flex-row justify-content-end">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className="no_style_button" to={paths?.collections}>
                Collecties
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {CapitalizeFirstLetter(collectionName)}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <CollectionItemCards
          collectionName={collectionName}
          items={cardItems}
          loading={loading}
        />
      </section>
    </Container>
  );
}

function CollectionsTopContent({ topContent }) {
  return (
    <Row xs={1} md={2} lg={3} className="g-2">
      {topContent?.map((i, key) => {
        return (
          <React.Fragment key={key}>
            <Col className="d-flex flex-column justify-content-center">
              {i?.type === "image" ? (
                <ProgressiveImage
                  src={i?.src}
                  alt={`subcollection_${Math.random()}`}
                  className="img-fluid"
                />
              ) : (
                <div className={" card_text_container"}>
                  <div className="card_text text-center">{i?.text}</div>
                </div>
              )}
            </Col>
          </React.Fragment>
        );
      })}
    </Row>
  );
}

function CollectionItemCards({ collectionName, items, loading }) {
  const isGrouped =
    items?.length > 0 &&
    Object?.values(items?.[0])?.some((value) => Array?.isArray(value));
  const placeholderItems = Array?.from({ length: items?.length || 4 });

  const renderCard = (item, index) => (
    <React.Fragment key={index}>
      <Col>
        <Card className="custom_card d-flex flex-column h-100">
          <ProgressiveImage
            src={item?.src}
            alt={`subcollection_${item?.title}`}
            className="img-fluid"
            isCardImage
          />
          <Card.Header className="text-center fw-bold pt-2 pl-2 pr-2 pb-2  card_title flex-fill">
            {item?.title && item?.title}
          </Card.Header>

          {item?.price && (
            <>
              <Card.Footer className="text-center price_text">
                {`${item?.price} euro`}
              </Card.Footer>
              <Card.Footer>
                <a
                  href={`mailto:info@nezceramics.be?subject=Aanvraag bestelling ${item?.title?.toLowerCase()} (${
                    item?.articleNumber
                  })&body=AANDACHT: gelieve het gewenste aantal verder aan te vullen.%0D%0A%0D%0AHier zijn de details van het product dat ik wil bestellen:%0D%0A%0D%0ATitel: ${
                    item?.title
                  }%0D%0AArtikelnummer: ${item?.articleNumber}%0D%0APrijs: ${
                    item?.price
                  } euro%0D%0ACollectienaam: ${collectionName}%0D%0AAantal:`}
                  className="btn third_button w-100 "
                >
                  Aanvragen
                </a>
              </Card.Footer>
            </>
          )}
        </Card>
      </Col>
    </React.Fragment>
  );

  const renderGroup = (group, index) => (
    <React.Fragment key={index}>
      <div className={`mb-4  ${index !== 0 ? "mt-4" : ""} text-end`}>
        <SectionTitle title={CapitalizeFirstLetter(Object?.keys(group)?.[0])} />
      </div>
      <Row xs={1} md={2} lg={3} xl={4} className="g-3">
        {group?.[Object?.keys(group)?.[0]]?.map(renderCard)}
      </Row>
    </React.Fragment>
  );

  const renderItems = () => {
    if (loading) {
      return placeholderItems?.map(renderPlaceholderCard);
    } else if (isGrouped) {
      return items?.map(renderGroup);
    } else {
      return items?.map(renderCard);
    }
  };
  const renderPlaceholderCard = (_, index) => (
    <React.Fragment key={index}>
      <Col>
        <Card className="custom_card d-flex flex-column h-100">
          <div className="img_placeholder_container">
            <Placeholder animation="glow" className="img-fluid" />
          </div>
          <Card.Header className="text-center fw-bold pt-2 pl-2 pr-2 card_title flex-fill">
            <Placeholder animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
          </Card.Header>
          <Card.Footer className="text-center price_text">
            <Placeholder animation="glow">
              <Placeholder xs={7} />
            </Placeholder>
          </Card.Footer>
          <Card.Footer>
            <Placeholder.Button
              animation="glow"
              variant="primary"
              className="btn third_button w-100"
              xs={12}
            />
          </Card.Footer>
        </Card>
      </Col>
    </React.Fragment>
  );

  return (
    <div>
      {isGrouped ? (
        renderItems()
      ) : (
        <Row xs={1} md={2} lg={3} xl={4} className="g-3">
          {renderItems()}
        </Row>
      )}
    </div>
  );
}
