import React from "react";
import { Container } from "react-bootstrap";
import SectionTitle from "../components/titles/sectionTitle";

export default function NotFound({ unauthorized }) {
  const errorCode = unauthorized ? "403" : "404";
  const errorTitle = unauthorized
    ? "Oops, you don't have access to this page"
    : "Oops, this page doesn't exist";

  return (
    <section className="d-flex align-items-center justify-content-center notFound_section">
      <Container>
        <div className="text-center">
          <SectionTitle title={errorCode} />
          <h4 className="card_title">{errorTitle}</h4>
        </div>
      </Container>
    </section>
  );
}
