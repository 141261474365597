import React, { useEffect } from "react";
import { useLocation } from "react-router";

import RouterNavigation from "./navigation/routerNavigation";

export default function App() {
  return (
    <>
      <ScrollToTop />
      <RouterNavigation />
    </>
  );
}

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};
