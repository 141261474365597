import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";

import CollectionItemsContent from "../components/content/collectionItemsContent";
import { CapitalizeFirstLetter } from "../functions/general";

export default function CollectionItems() {
  const { collectionName } = useParams();

  const helmetTitle = `Collecties | ${CapitalizeFirstLetter(
    collectionName
  )} | Ñezceramics`;
  const currentPath = `https://nezceramics.be/collections/${collectionName}`;

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
        <meta
          name="description"
          content="Ontdek Ñezceramics: een plek waar passie voor keramiek, grafisch ontwerp, en de natuur samenkomen. Elk stuk weerspiegelt een uniek verhaal van creativiteit en finesse, zorgvuldig gevormd in ons atelier."
        />
        <meta property="og:title" content={helmetTitle} />
        <meta
          property="og:description"
          content="Ontdek Ñezceramics: een plek waar passie voor keramiek, grafisch ontwerp, en de natuur samenkomen. Elk stuk weerspiegelt een uniek verhaal van creativiteit en finesse, zorgvuldig gevormd in ons atelier."
        />
        <meta property="og:url" content={currentPath} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={currentPath} />
      </Helmet>
      <CollectionItemsContent collectionName={collectionName} />
    </>
  );
}
