import React from "react";

import { ListGroup } from "react-bootstrap";

function ShoppingCart({ items, show, setShow }) {
  if (items <= 0) {
    return <NoItemsContent show={show} setShow={setShow} />;
  }
  return <ItemsContent items={items} />;
}

const ItemsContent = ({ items }) => {
  const title = `Items in basket ${items}`;
  return (
    <ListGroup>
      <div className="general_title">{title}</div>
    </ListGroup>
  );
};

const NoItemsContent = ({ show, setShow }) => {
  const title = "Uw winkelwagen is leeg";
  const buttonTitle = "Verder winkelen";

  const onClickHandler = () => {
    setShow(!show);
  };
  return (
    <>
      <h5 className="shopping_card_title text-center p-4">{title}</h5>
      <div className="d-flex flex-row justify-content-center">
        <button onClick={onClickHandler} className="btn m-3">
          {buttonTitle}
        </button>
      </div>
    </>
  );
};

export default ShoppingCart;
