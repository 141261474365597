import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { canvasTypes } from "../../constants/canvasTypes";
import ShoppingCart from "./shoppingCart";

export default function OffcanvasRight({
  show,
  setShow,
  leftSide,
  canvasID,
  cartItems,
}) {
  const onHideHandler = () => {
    setShow(false);
  };

  return (
    <>
      <Offcanvas
        className="offCanvas_section"
        show={show}
        onHide={onHideHandler}
        placement={leftSide ? "left" : "end"}
        scroll
      >
        {show && (
          <>
            <Offcanvas.Header closeButton className="modal_header">
              <Offcanvas.Title className="modal_title">
                <TitleHandler canvasID={canvasID} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="modal_body">
              <BodyHandler
                canvasID={canvasID}
                cartItems={cartItems}
                setShow={setShow}
                show={show}
              />
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  );
}

function BodyHandler({ canvasID, cartItems, show, setShow }) {
  switch (canvasID) {
    case canvasTypes?.cart:
      return <ShoppingCart show={show} setShow={setShow} items={cartItems} />;
    default:
      return null;
  }
}

function TitleHandler({ canvasID }) {
  switch (canvasID) {
    case canvasTypes?.cart:
      return "Uw winkelwagen";
    default:
      return null;
  }
}
