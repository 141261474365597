import logo from "../images/logo.png";
import watermark_trans from "../images/watermark_trans.png";
import aboutus1 from "../images/aboutus1.png";
import aboutus1_second from "../images/aboutus1_second.png";
import aboutus2 from "../images/aboutus2.png";
import aboutus2_second from "../images/aboutus2_second.png";
import aboutus3 from "../images/aboutus3.png";
import aboutus3_second from "../images/aboutus3_second.png";
import carousel1 from "../images/carousel1.png";
import carousel2 from "../images/carousel2.png";
import carousel3 from "../images/carousel3.png";
import carousel_aboutus1 from "../images/carousel_about1.png";
import carousel_aboutus2 from "../images/carousel_about2.png";
import carousel_aboutus3 from "../images/carousel_about3.png";
import cups from "../images/cups.png";
import featuresGeneral from "../images/features0.png";
import featuresGeneral_extra from "../images/features0_extra.png";
import features1 from "../images/features1.png";
import features2 from "../images/features2.png";
import features2Extra from "../images/features2_extra.png";

import features3 from "../images/features3.png";
import features4 from "../images/features4.png";

export const imageProps = {
  logo: {
    src: logo,
    width: "1920",
    height: "1920",
    alt: "logo",
  },
  watermark_trans: {
    src: watermark_trans,
    width: "1920",
    height: "1920",
    alt: "watermark_trans",
  },
  aboutus1: {
    src: aboutus1,
    width: "1920",
    height: "1920",
    alt: "Aboutus1",
  },
  aboutus1_second: {
    src: aboutus1_second,
    width: "1920",
    height: "1920",
    alt: "Aboutus1_second",
  },
  aboutus2: {
    src: aboutus2,
    width: "1920",
    height: "1920",
    alt: "Aboutus2",
  },
  aboutus2_second: {
    src: aboutus2_second,
    width: "1920",
    height: "1920",
    alt: "Aboutus2_second",
  },
  aboutus3: {
    src: aboutus3,
    width: "1920",
    height: "1920",
    alt: "Aboutus3",
  },
  aboutus3_second: {
    src: aboutus3_second,
    width: "1920",
    height: "1920",
    alt: "Aboutus3_second",
  },
  carousel1: {
    src: carousel1,
    width: "auto",
    height: "auto",
    alt: "Carsousel1",
  },
  carousel2: {
    src: carousel2,
    width: "auto",
    height: "auto",
    alt: "Carsousel2",
  },
  carousel3: {
    src: carousel3,
    width: "auto",
    height: "auto",
    alt: "Carousel3",
  },
  carousel_aboutus1: {
    src: carousel_aboutus1,
    width: "auto",
    height: "auto",
    alt: "CarsouselAbout1",
  },
  carousel_aboutus3: {
    src: carousel_aboutus3,
    width: "auto",
    height: "auto",
    alt: "Carousel_aboutus3",
  },
  carousel_aboutus2: {
    src: carousel_aboutus2,
    width: "auto",
    height: "auto",
    alt: "CarsouselAbout2",
  },
  banner1: {
    src: carousel1,
    width: "auto",
    height: "auto",
    alt: "Banner1",
  },
  banner_cups: {
    src: cups,
    width: "auto",
    height: "auto",
    alt: "Banner_about",
  },
  banner_about: {
    src: carousel_aboutus2,
    width: "auto",
    height: "auto",
    alt: "Banner_about",
  },
  featuresGeneral: {
    src: featuresGeneral,
    width: "1920",
    height: "1920",
    alt: "Features0",
  },
  featuresGeneral_extra: {
    src: featuresGeneral_extra,
    width: "1920",
    height: "1920",
    alt: "Features0_extra",
  },
  features1: {
    src: features1,
    width: "1920",
    height: "1920",
    alt: "Features1",
  },
  features2: {
    src: features2,
    width: "1920",
    height: "1920",
    alt: "Features2",
  },
  features2Extra: {
    src: features2Extra,
    width: "1920",
    height: "1920",
    alt: "Features2Extra",
  },
  features3: {
    src: features3,
    width: "1920",
    height: "1920",
    alt: "Features3",
  },
  features4: {
    src: features4,
    width: "1920",
    height: "1920",
    alt: "Features4",
  },
};
