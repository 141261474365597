import { createContext, useContext, useState, useEffect } from "react";
import { collection, getDocs, writeBatch } from "firebase/firestore";
import { firestore } from "./config";

export const FirestoreContext = createContext();

export function ProvideFirestore({ children }) {
  const firestoreService = useProvideFirestore();
  return (
    <FirestoreContext.Provider value={firestoreService}>
      {children}
    </FirestoreContext.Provider>
  );
}

export const useFirestore = () => {
  return useContext(FirestoreContext);
};

function useProvideFirestore() {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCollections({ setCollections, setLoading });
  }, []);

  return {
    collections,
    loading,
  };
}

async function fetchCollections({ setCollections, setLoading }) {
  try {
    const querySnapshot = await getDocs(collection(firestore, "collections"));
    const collectionsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCollections(collectionsData);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching collections: ", error);
    setLoading(false);
  }
}

async function uploadCollections({ setCollections, setLoading }) {
  setLoading(true);

  try {
    const querySnapshot = await getDocs(collection(firestore, "collections"));
    let batch = writeBatch(firestore);
    let collectionsData = [];

    querySnapshot.docs.forEach((docSnapshot) => {
      let data = docSnapshot.data();

      // Update the title if it contains 'SLIP'
      if (data.title && data.title.includes("SLIP")) {
        data.title = data.title.replace(/SLIP/g, "SLIB");
      }

      // Update the id if it contains 'SLIP'
      if (docSnapshot.id.includes("SLIP")) {
        data.id = docSnapshot.id.replace(/SLIP/g, "SLIB");
      }

      // Loop through subcollections to update items array names
      if (data.subcollections && data.subcollections.length > 0) {
        data.subcollections.forEach((subcol) => {
          if (subcol.items && subcol.items.length > 0) {
            subcol.items.forEach((item, index) => {
              let keys = Object.keys(item);
              keys.forEach((key) => {
                if (key.includes("slip")) {
                  let newKey = key.replace(/slip/gi, "slib");
                  subcol.items[index][newKey] = subcol.items[index][key];
                  delete subcol.items[index][key];
                }
              });
            });
          }
        });
      }

      batch.set(docSnapshot.ref, data);

      collectionsData.push({
        id: data.id,
        ...data,
      });
    });

    if (!batch.isEmpty) {
      await batch.commit();
      console.log("Collections and their items updated with new names");
    } else {
      console.log("No collections needed updating");
    }

    setCollections(collectionsData);
  } catch (error) {
    console.error("Error fetching or updating collections: ", error);
  } finally {
    setLoading(false);
  }
}
