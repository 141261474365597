import React, { useEffect, useState, useCallback } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";

import ProgressiveImage from "../images/progressiveImage";
import { imageProps } from "../../constants/imageProps";
import { Navigation_ARRAY } from "../../constants/arrays";
import { paths } from "../../constants/paths";
import OffcanvasRight from "../modals/offcanvasRight";
import { canvasTypes } from "../../constants/canvasTypes";

function Navigation({ cartItems, location }) {
  const [modal, setModal] = useState(false);
  const [openCart, setOpenCart] = useState(false);

  return (
    <>
      <Navbar
        fixed="top"
        collapseOnSelect
        expanded={modal}
        expand="md"
        className="navbar_container border-bottom"
      >
        <Container className="navbar_content">
          <NavIcon />
          <ToggleButton modal={modal} setModal={setModal} />
          <CollapsedContent>
            <LeftItems
              location={location}
              openCart={openCart}
              setOpenCart={setOpenCart}
              modal={modal}
              setModal={setModal}
            />

            <RightItems
              location={location}
              openCart={openCart}
              setOpenCart={setOpenCart}
              modal={modal}
              setModal={setModal}
              floatLeft
            />
          </CollapsedContent>
        </Container>
      </Navbar>
      <OffcanvasRight
        show={openCart}
        setShow={() => setOpenCart(!openCart)}
        canvasID={canvasTypes?.cart}
        cartItems={cartItems}
      />
    </>
  );
}

function LeftItems({ location, openCart, setOpenCart, modal, setModal }) {
  const items = Navigation_ARRAY()?.filter(
    (item) => item?.withLeftNavbar === true
  );

  return (
    <Nav className="me-auto">
      {items?.map((item, key) => {
        return (
          <React.Fragment key={key}>
            <NavItem
              location={location}
              modal={modal}
              setOpenCart={setOpenCart}
              openCart={openCart}
              setModal={setModal}
              link={item?.link}
              title={item?.title}
            />
          </React.Fragment>
        );
      })}
    </Nav>
  );
}

function RightItems({
  location,
  openCart,
  setOpenCart,
  floatLeft,
  modal,
  setModal,
}) {
  const items = Navigation_ARRAY()?.filter(
    (item) => item?.withRightNavbar === true
  );

  return (
    <Nav className={floatLeft && "ms-auto"}>
      {items?.map((item, key) => {
        return (
          <React.Fragment key={key}>
            <NavItem
              modal={modal}
              location={location}
              setModal={setModal}
              setOpenCart={setOpenCart}
              openCart={openCart}
              link={item?.link}
              title={item?.title}
              icon={item?.icon}
            />
          </React.Fragment>
        );
      })}
    </Nav>
  );
}

function NavIcon({ isCentered }) {
  return (
    <Link
      to={paths?.index}
      className={`p-0 ${
        isCentered && "m-0 d-flex flex-row brand-link justify-content-center"
      }`}
    >
      <ProgressiveImage
        src={imageProps?.generalTrans?.src}
        alt={imageProps?.generalTrans?.alt}
        height={imageProps?.generalTrans?.height}
        width={imageProps?.generalTrans?.width}
        className="img-fluid navbar_img"
      />
    </Link>
  );
}

function CollapsedContent({ children }) {
  return (
    <Navbar.Collapse id="responsive-navbar-nav">{children}</Navbar.Collapse>
  );
}

function ToggleButton({ modal, setModal }) {
  const onHandleClick = () => {
    setModal(!modal);
  };
  return (
    <Navbar.Toggle
      onClick={onHandleClick}
      aria-controls="responsive-navbar-nav"
      aria-label="navbar logo"
    />
  );
}

export function NavItem({
  setModal,
  setOpenCart,
  openCart,
  link,
  title,
  icon,
  customClassName,
  target,
  isCustomButton,
  children,
  className,
  onClick,
  to,
  ariaLabel,
}) {
  if (isCustomButton) {
    return (
      <NavLink
        to={to}
        aria-label={ariaLabel}
        className={className}
        end
        target={target}
      >
        {children}
      </NavLink>
    );
  } else {
    return (
      <NavLink
        to={link}
        aria-label={ariaLabel}
        className={({ isActive }) =>
          isActive
            ? `${customClassName ? customClassName : ""} active_item nav-link`
            : `${customClassName ? customClassName : ""} nav-link`
        }
        target={target}
      >
        <div className={"d-flex flex-row align-items-center"}>
          {icon && <span className="nav_icon">{icon}</span>}
          {title && <span>{title}</span>}
        </div>
      </NavLink>
    );
  }
}

export default Navigation;
