import React, { useMemo } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";

import {
  Navigation_ARRAY,
  Navigation_CONTACT_ARRAY,
  Navigation_PDF_ARRAY,
  Socialmedia_ARRAY,
} from "../../constants/arrays";
import { imageProps } from "../../constants/imageProps";
import ProgressiveImage from "../images/progressiveImage";
import { NavItem } from "../navbar/customNavbar";

const Footer = ({ smallFooter, aboutFooter, cupsFooter }) => {
  const navigationArr = Navigation_ARRAY()?.filter(
    (item) => item?.withFooter === true
  );
  const navigationPDFArr = Navigation_PDF_ARRAY()?.filter(
    (item) => item?.withFooter === true
  );
  const navigationContactArr = Navigation_CONTACT_ARRAY();
  const socialMediaArr = Socialmedia_ARRAY();

  const renderNavItems = useMemo(() => {
    return navigationArr?.map((item, key) => (
      <React.Fragment key={key}>
        <NavItem
          link={item?.link}
          title={item?.title}
          customClassName={item?.footerClassName}
        />
      </React.Fragment>
    ));
  }, [navigationArr]);

  const renderPDFItems = useMemo(() => {
    return navigationPDFArr?.map((item, key) => (
      <React.Fragment key={key}>
        <NavItem
          target={item?.target}
          link={item?.link}
          title={item?.title}
          customClassName={item?.footerClassName}
        />
      </React.Fragment>
    ));
  }, [navigationPDFArr]);

  const renderContactItems = useMemo(() => {
    return navigationContactArr?.map((item, key) => (
      <React.Fragment key={key}>
        <NavItem
          link={item?.link}
          title={item?.title}
          icon={item?.icon}
          customClassName={item?.footerClassName}
        />
      </React.Fragment>
    ));
  }, [navigationContactArr]);

  return (
    <footer>
      <FooterContent>
        {!smallFooter && (
          <ProgressiveImage
            src={
              aboutFooter
                ? imageProps?.banner_about?.src
                : cupsFooter
                ? imageProps?.banner_cups?.src
                : imageProps?.banner1?.src
            }
            alt={imageProps?.banner1?.alt}
            height={imageProps?.banner1?.height}
            width={imageProps?.banner1?.width}
            className="img-fluid w-100 banner_image"
          />
        )}
        <div className="text-center pt-4">
          <ProgressiveImage
            src={imageProps?.watermark_trans?.src}
            alt={imageProps?.watermark_trans?.alt}
            height={imageProps?.watermark_trans?.height}
            width={imageProps?.watermark_trans?.width}
            className="img-fluid footer_logo_img"
          />
        </div>
        <Container fluid className="pt-4 ">
          <Row>
            <FooterColumnContainer>{renderNavItems}</FooterColumnContainer>
            <FooterColumnContainer>{renderPDFItems}</FooterColumnContainer>
            <FooterColumnContainer>{renderContactItems}</FooterColumnContainer>
          </Row>
        </Container>
        <FooterBottomContent items={socialMediaArr} />
      </FooterContent>
    </footer>
  );
};

const FooterContent = ({ children }) => {
  return <div className="footer_content footer_bg">{children}</div>;
};
const FooterColumnContainer = ({ children }) => {
  return (
    <Col className="d-flex justify-content-center footer_col" md={4}>
      <Nav className="flex-column">{children}</Nav>
    </Col>
  );
};

const FooterBottomContent = ({ items }) => {
  const text = "Ñez | Ceramics, Inc. All rights reserved.";
  return (
    <Container className="pb-4">
      <Nav className="list-unstyled d-flex justify-content-center">
        {items?.map((item, key) => {
          return (
            <React.Fragment key={key}>
              <NavItem
                target={item?.target}
                link={item?.link}
                icon={item?.icon}
                ariaLabel={item?.ariaLabel}
              />
            </React.Fragment>
          );
        })}
      </Nav>
      <span className="d-flex justify-content-center pt-2 rights_text">
        © {new Date().getFullYear()} {text}
      </span>
    </Container>
  );
};

export default Footer;
