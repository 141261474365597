import React from "react";
import { Container } from "react-bootstrap";
import SectionTitle from "../components/titles/sectionTitle";

export default function LegalNotice() {
  return (
    <Container>
      <section className="custom_section">
        <div className="d-flex flex-row justify-content-center">
          <SectionTitle title={"WETTELIJKE KENNISGEVING"} />
        </div>
        <div className="custom_section_top card_text">
          <p>
            ÑEZCERAMICS heeft alle rechten op de inhoud en lay-out van haar
            website. De intellectuele eigendommen op onze website en van onze
            merken zijn beschermd. De tekst, afbeeldingen en grafische elementen
            op onze website en hun lay-out, evenals animaties en software zijn
            onderhevig aan auteursrechtwetgeving en andere beschermende
            wetgeving. Reproductie, weergave of afspelen van het geheel of delen
            hiervan is verboden tenzij er voorafgaande schriftelijke toestemming
            is gegeven. Deze website kent geen licenties toe voor het gebruik
            van intellectuele eigendommen. ÑEZCERAMICS behoudt het recht om haar
            website aan te passen, op te schorten of af te sluiten op eender
            welk moment en zonder voorafgaande kennisgeving.
          </p>
          <p>
            Ondanks zorgvuldige samenstelling van de inhoud van onze website,
            zijn we niet aansprakelijk voor de correctheid, nauwkeurigheid,
            volledigheid of voor ononderbroken toegang tot de website. Voor
            zover dit wettelijk is toegestaan, zijn we niet aansprakelijk voor
            rechtstreekse of onrechtstreekse schade, met inbegrip van
            inkomstenverlies, die voortkomt uit het gebruik van onze website of
            verband houdend met informatie die beschikbaar is op de website. We
            behouden het recht om de geleverde informatie te wijzigen of aan te
            vullen en dit op eender welk moment.
          </p>
        </div>
      </section>
    </Container>
  );
}
