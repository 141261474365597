import emailjs from "emailjs-com";

import { templates } from "../constants/templates";

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

export default function SendEmail({ params, onSuccess, onError }) {
  const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TEMPLATE_ID = templates?.contactForm;

  emailjs
    .send(SERVICE_ID, TEMPLATE_ID, params)
    .then((response) => onSuccess(response))
    .catch((error) => onError(error));
}
