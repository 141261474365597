import React from "react";
import { Helmet } from "react-helmet";

import CustomCarousel from "../components/carousel/customCarousel";
import FeaturesContent from "../components/content/featuresContent";
import WelcomeContent from "../components/content/welcomeContent";
import { carouselID } from "../constants/carouselID";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Welkom | Ñezceramics</title>
        <meta
          name="description"
          content="Ontdek bij Ñezceramics een wereld van unieke handgemaakte keramiek, waar ambacht, creativiteit en passie samenkomen. Elk stuk vertelt een eigen verhaal, gemaakt met liefde en toewijding in kleine oplagen."
        />
        <meta property="og:title" content="Welkom - Ñezceramics" />
        <meta
          property="og:description"
          content="Ontdek bij Ñezceramics een wereld van unieke handgemaakte keramiek, waar ambacht, creativiteit en passie samenkomen. Elk stuk vertelt een eigen verhaal, gemaakt met liefde en toewijding in kleine oplagen."
        />
        <meta property="og:url" content="https://nezceramics.be" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://nezceramics.be" />
      </Helmet>
      <CustomCarousel id={carouselID?.home} />
      <WelcomeContent />
      <FeaturesContent />
    </>
  );
}
