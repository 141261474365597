import React, { useMemo } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";

import SectionTitle from "../../components/titles/sectionTitle";
import GoogleMaps from "../../components/maps/googleMaps";
import ContactForm from "../../components/forms/contactForm";
import { NavItem } from "../navbar/customNavbar";
import { Socialmedia_ARRAY } from "../../constants/arrays";

export default function ContactContent() {
  return (
    <Container>
      <section className="custom_section">
        <div className="d-flex flex-row justify-content-end">
          <SectionTitle title="Contact" />
        </div>
        <div className="custom_section_top">
          <GoogleMaps />
          <ContactTitle />
          <Row noGutters>
            <Col
              className="d-flex flex-column"
              xs={{ span: 12, order: 1 }}
              md={{ span: 4, order: 1 }}
              lg={{ span: 5, order: 1 }}
            >
              <AddressContent />
            </Col>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 8, order: 2 }}
              lg={{ span: 7, order: 2 }}
            >
              <ContactForm />
            </Col>
          </Row>
        </div>
      </section>
    </Container>
  );
}

function ContactTitle() {
  return (
    <div className="custom_section">
      <h5 className="card_title text-center">
        Heeft u een vraag of opmerking?
        <br />
        Contacteer ons via onderstaand formulier!
      </h5>
    </div>
  );
}

function AddressContent() {
  const socialMediaArr = Socialmedia_ARRAY();

  const renderContactItems = useMemo(() => {
    return socialMediaArr?.map((item, key) => (
      <React.Fragment key={key}>
        <NavItem
          target={item?.target}
          link={item?.link}
          icon={item?.icon}
          ariaLabel={item?.ariaLabel}
        />
      </React.Fragment>
    ));
  }, [socialMediaArr]);

  return (
    <div className="card_text_container">
      <div className="card_text custom_card_text">
        Ñezceramics
        <br />
        Chau. de la Libération 15
        <br />
        7750 Mont-de-l'Enclus
        <br />
        België
        <br />
        <br />
        <Nav className="list-unstyled d-flex justify-content-center pb-4">
          {renderContactItems}
        </Nav>
      </div>
    </div>
  );
}
