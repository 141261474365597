import React from "react";
import { Container } from "react-bootstrap";

import { paths } from "../../constants/paths";
import { NavItem } from "../navbar/customNavbar";
import WelcomeTitle from "../titles/welcomeTitle";

export default function WelcomeContent() {
  return (
    <>
      <section className="welcome_section">
        <Container className="pt-4 pb-4">
          <div className="d-flex flex-row justify-content-between flex-wrap">
            <div className="d-flex  flex-column ">
              <WelcomeTitle title="Handmade" />
              <br />
              <WelcomeTitle title="Ceramics" />
            </div>
            <div className="d-flex flex-column justify-content-md-end">
              <NavItem
                isCustomButton
                className=" welcome_button mt-4 mb-2"
                to={paths?.collections}
              >
                Collecties
              </NavItem>
              <NavItem
                isCustomButton
                className="welcome_button"
                to={paths?.about}
              >
                Over ÑEZ
              </NavItem>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
