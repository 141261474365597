import React from "react";
import { Helmet } from "react-helmet";

import CollectionsContent from "../components/content/collectionsContent";

export default function Collections() {
  return (
    <>
      <Helmet>
        <title>Collecties | Ñezceramics</title>
        <meta
          name="description"
          content="Ontdek Ñezceramics: een plek waar passie voor keramiek, grafisch ontwerp, en de natuur samenkomen. Elk stuk weerspiegelt een uniek verhaal van creativiteit en finesse, zorgvuldig gevormd in ons atelier."
        />
        <meta property="og:title" content="Collecties | Ñezceramics" />
        <meta
          property="og:description"
          content="Ontdek Ñezceramics: een plek waar passie voor keramiek, grafisch ontwerp, en de natuur samenkomen. Elk stuk weerspiegelt een uniek verhaal van creativiteit en finesse, zorgvuldig gevormd in ons atelier."
        />
        <meta property="og:url" content="https://nezceramics.be/collections" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://nezceramics.be/collections" />
      </Helmet>
      <CollectionsContent />
    </>
  );
}
